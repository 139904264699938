import { useEffect, useState } from 'react'
import ProgressBar from '../../component/common/Progressbar/ProgressBar'
import { LS_PHONE_NUMBER, callPage, storageKeys } from '../../utils/constants'
import { EmergencyDisclaimer } from '../../component/common/EmergencyDisclaimer/EmergencyDisclaimer'
import { useNavigate } from 'react-router-dom'
import SupportRequest from '../../component/pages/reason-for-coaching-support/SupportRequest'
import { PercentageQuestions, Services } from "../../utils/member-portal/constant";
import UserInformation from '../../component/pages/user-information/UserInformation'
import MeetYourCoach from '../../component/pages/meet-coach/MeetYourCoach'
import AgeGate from '../../component/pages/age-gate/AgeGate'
import GeneralSelfSchedulingQuestions from '../../component/pages/self-schedule/GeneralSelfSchedulingQuestions'
import RadioButtonAssessmentPage1 from '../../component/pages/self-schedule/RadioButtonAssessmentPage1'
import RadioButtonAssessmentPage2 from '../../component/pages/self-schedule/RadioButtonAssessmentPage2'
import EnvironmentConfig from '../../component/config/EnvironmentConfig'

type CompletionStatus = {
    textCoachingQuestion: boolean;
    ageGate: boolean;
    questionOne: boolean;
    questionTwo: boolean;
    questionThree: boolean;
    userInfoForm: boolean;
    selfSchedulingQuestion: boolean;
    radioButtonAssessmentPage1: boolean;
    radioButtonAssessmentPage2: boolean;
};

export type AnswersObj = {
    textCoachingQuestion: string | null;
    ageGate: string | null;
    questionOne: string | null;
    questionTwo: string | null;
    questionThree: string | null;
    userInfoForm: object | null;
    selfSchedulingQuestion: object | null;
    radioButtonAssessmentPage1: object | null;
    radioButtonAssessmentPage2: object | null;
};

const SupportQuestionnaire = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>(callPage.NUMBER);
    const [isTicketCreated, setIsTicketCreated] = useState<boolean>(false)
    const flow = localStorage.getItem(storageKeys.Flow)
    const textCoachingQuestion = localStorage.getItem(storageKeys.TextCoachingQuestion)
    const navigate = useNavigate();

    const [completionStatus, setCompletionStatus] = useState<CompletionStatus>({
        textCoachingQuestion: false,
        ageGate: false,
        questionOne: false,
        questionTwo: false,
        questionThree: false,
        userInfoForm: false,
        selfSchedulingQuestion: false,
        radioButtonAssessmentPage1: false,
        radioButtonAssessmentPage2: false
    });

    const [answers, setAnswers] = useState<AnswersObj>({
        textCoachingQuestion: null,
        ageGate: null,
        questionOne: null,
        questionTwo: null,
        questionThree: null,
        userInfoForm: null,
        selfSchedulingQuestion: null,
        radioButtonAssessmentPage1: null,
        radioButtonAssessmentPage2: null
    });

    const steps = [
        {
            key: 'textCoachingQuestion' as keyof CompletionStatus,
            component: (
                <SupportRequest
                    onNext={(answer: string) => handleNextStep('textCoachingQuestion', answer)}
                />
            ),
            condition: flow === Services.textCoaching && !textCoachingQuestion,
        },
        {
            key: 'ageGate' as keyof CompletionStatus,
            component: (
                <AgeGate
                    onNext={(answer: string) => handleNextStep('ageGate', answer)}
                />
            )
        },
        {
            key: 'questionOne' as keyof CompletionStatus,
            title: PercentageQuestions.questionOne,
        },
        {
            key: 'questionTwo' as keyof CompletionStatus,
            title: PercentageQuestions.questionTwo,
        },
        {
            key: 'questionThree' as keyof CompletionStatus,
            title: PercentageQuestions.questionThree,
        },
        {
            key: 'userInfoForm' as keyof CompletionStatus,
            component: (
                <UserInformation onComplete={(answer: object) => handleNextStep('userInfoForm', answer)} setIsTicketCreated={setIsTicketCreated} answers={answers} />
            ),
        },
        {
            key: 'selfSchedulingQuestion' as keyof CompletionStatus,
            component: (
                <GeneralSelfSchedulingQuestions
                    onNext={(answer: object) => handleNextStep('selfSchedulingQuestion', answer)}
                    answers={answers}
                />
            ),
            condition: flow === Services.selfSchedule,
        },
        {
            key: 'radioButtonAssessmentPage1' as keyof CompletionStatus,
            component: (
                <RadioButtonAssessmentPage1
                    onNext={(answer: object) => handleNextStep('radioButtonAssessmentPage1', answer)}
                    answers={answers}
                />
            ),
            condition: flow === Services.selfSchedule && EnvironmentConfig.show_gad7_phq9,
        },
        {
            key: 'radioButtonAssessmentPage2' as keyof CompletionStatus,
            component: (
                <RadioButtonAssessmentPage2
                    onNext={(answer: object) => handleNextStep('radioButtonAssessmentPage2', answer)}
                    answers={answers}
                    stepkey={"radioButtonAssessmentPage2"}
                />
            ),
            condition: flow === Services.selfSchedule && EnvironmentConfig.show_gad7_phq9,
        },
    ];

    const filteredSteps = steps.filter((step) => step.condition === undefined || step.condition);

    const currentStepIndex = filteredSteps.findIndex(
        (step) => step.key && !completionStatus[step.key as keyof CompletionStatus]
    );

    const lastStepIndex = filteredSteps.length - 1;

    const isLastStep = currentStepIndex === lastStepIndex;

    const currentStep = filteredSteps[currentStepIndex];

    const totalStepsForProgress = steps.filter(step => step.key && (step.condition === undefined || step.condition)).length;
    const completedStepsForProgress = steps.filter(
        step => step.key && completionStatus[step.key as keyof CompletionStatus] && (step.condition === undefined || step.condition)
    ).length;

    const progress = (completedStepsForProgress / totalStepsForProgress) * 100;

    const handleNextStep = (key: keyof CompletionStatus, answer: string | object) => {
        setCompletionStatus((prevStatus) => ({
            ...prevStatus,
            [key]: true,
        }));
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [key]: answer,
        }));
    };

    const handleResponseYes = (answer: string) => {
        localStorage.setItem(storageKeys.RegistrationBlock, answer);
        navigate("/call-page");
    };

    useEffect(() => {
        setPhoneNumber(localStorage.getItem(LS_PHONE_NUMBER) ?? callPage.NUMBER);
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const warningMessage = "You have unsaved changes. Are you sure you want to leave?";
            event.preventDefault();
            event.returnValue = warningMessage;
            return warningMessage;
        };

        const handlePopState = (event: PopStateEvent) => {
            if (!isLastStep) {
                const confirmation = window.confirm("You have unsaved changes. Are you sure you want to go back?");
                if (!confirmation) {
                    event.preventDefault();
                    window.history.pushState(null, "");
                }
            }
        };

        if (!isLastStep) {
            window.addEventListener("beforeunload", handleBeforeUnload);
            window.addEventListener("popstate", handlePopState);
            window.history.pushState(null, "");
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("popstate", handlePopState);
        };
    }, [isLastStep]);


    return (
        <div className="page-center-container">
            {currentStep && (
                <>
                    {currentStep.component ? (
                        currentStep.component
                    ) : (
                        <>
                            <EmergencyDisclaimer phoneNumber={phoneNumber} />
                            <span className="subtitle">Please answer a few questions</span>
                            <span className="title">{currentStep.title}</span>
                            <div className="question-button-div">
                                <button
                                    className="green-rounded-btn"
                                    onClick={() => handleNextStep(currentStep.key as keyof CompletionStatus, 'No')}
                                >
                                    No
                                </button>
                                <button
                                    className="green-border-button"
                                    onClick={() => handleResponseYes('Yes')}
                                >
                                    Yes
                                </button>
                            </div>
                        </>
                    )}
                </>
            )}
            {
                isTicketCreated && <MeetYourCoach />
            }
            <ProgressBar progress={progress} />
        </div>
    )
}

export default SupportQuestionnaire