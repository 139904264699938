import { WorkLifeCategory } from "../../../../utils/constants";
import {
  Category,
  workLifeData,
  Request,
} from "../../../../utils/workLifeData";
import "../../../../styles/gridCard.scss";
import "../../../../styles/globalStyles.scss";
import { Stepkeys, WorkLifeAnswersObj } from "../../../../views/worklife-request/WorkLifeRequestSteps";

interface Categoriesprops {
  setCompletionStatus: (key: string, isCompleted: boolean) => void;
  setAnswers: <K extends keyof WorkLifeAnswersObj>(key: K, value: WorkLifeAnswersObj[K]) => void;
}

const WorkLifeCategories: React.FC<Categoriesprops> = ({ setCompletionStatus, setAnswers }) => {
  const handleNavigation = (item: Request | Category) => {
    setCompletionStatus(Stepkeys.categories, true);
    setAnswers(Stepkeys.categories, { categoryId: item.id, categoryName: item.name });
  };


  return (
    <div className="worklife-category-page page-container">
      <span className="title">{WorkLifeCategory.CATEGORY_TITLE}</span>
      <div className="grid-container">
        {workLifeData?.map((item: Category, index: number) => {
          return (
            <div
              className="card"
              key={index}
              onClick={() => handleNavigation(item)}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WorkLifeCategories;
