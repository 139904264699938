import ExploreTopicCard from '../../component/common/Cards/ExploreTopicCard'
import './ExploreTopics.scss'
import { useEffect, useRef, useState } from 'react'
import { exploreTopicsCategories } from '../../utils/ApiClient'
import { ApiCareSquadCategoryV2 } from '../../interfaces/ApiTypes'
import Loader from '../../component/common/Loader/Loader'
import { capitalizeFirstLetter } from '../../utils/helperFunctions'
import useGlobalState from '../../context/useGlobalState'

const ExploreTopics = () => {
    const [topicList, setTopicList] = useState<ApiCareSquadCategoryV2[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const { globalErrorHandler } = useGlobalState()
    const effectRef = useRef(false)

    useEffect(() => {
        const fetchCategoryCounts = async (): Promise<void> => {
            try {
                const response = await exploreTopicsCategories()
                setTopicList(response.data)
                setLoading(false)
            } catch (error) {
                globalErrorHandler(error)
                setLoading(false)
            }
        }
        if (effectRef.current === false) {
            fetchCategoryCounts()
        }
        return () => { effectRef.current = true }
    }, [])

    return (
        <div>
            {
                loading ? <Loader /> : <div className='explore-topic-page container'>
                    <div className='header-section'>
                        <div className='explore-title'>{"Explore Topics"}</div>
                        <span className='explore-content'>{"Here you'll find comprehensive information and resources related to personal and professional well-being. For customized support, please reach out to us 24/7 (add link or phone #? )"}</span>
                    </div>
                    {
                        topicList.map((item: ApiCareSquadCategoryV2, index: number) => {
                            if (!item.subcategoryData.length) return
                            return <div className='sub-topic' key={"cat" + index}>
                                <div className='sub-topic-header'>
                                    <img src={item.icon} alt='icon' className='sub-topic-icon' />
                                    <span className='header-text'>{capitalizeFirstLetter(item.label)}</span>
                                </div>
                                <div className='explore-card-container'>
                                    {
                                        item.subcategoryData.map((item, index) => {
                                            return <ExploreTopicCard
                                                key={"t-card" + index}
                                                id={item.id}
                                                image={item.thumbnailUrl}
                                                title={capitalizeFirstLetter(item.name)}
                                                digitalContCount={item.articleCount}
                                                exerciseCount={item.moduleCount} />
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
            }
        </div>

    )
}

export default ExploreTopics