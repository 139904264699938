import './IndividualSupport.scss'
import React, { useEffect, useRef, useState } from "react";
import PreviousIcon from '../component/assests/icons/previous.png';
import NextIcon from '../component/assests/icons/next.png';
import SupportCard from '../component/common/Cards/SupportCard';
import EnvironmentConfig from '../component/config/EnvironmentConfig';
import { storageKeys } from '../utils/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Services } from '../utils/member-portal/constant';
import { ticketList } from '../utils/ApiClient';
import useGlobalState from '../context/useGlobalState';

interface Props {
    title: string;
    icon?: string;
}

const IndividualSupport: React.FC<Props> = ({ title, icon }) => {
    const sliderRef = useRef<SwiperCore | null>(null);
    const serviceType = localStorage.getItem(storageKeys.AvailableService)
    const [activeTickets, setActiveTickets] = useState<number | undefined>()
    const { globalErrorHandler } = useGlobalState()

    const getActiveTicketCounts = async (): Promise<void> => {
        try {
            const response = await ticketList()
            setActiveTickets(response.data.activeTicketCount);
        } catch (error) {
            globalErrorHandler(error)
        }
    }

    useEffect(() => {
        getActiveTicketCounts()
    }, [])

    const Cards = [
        {
            title: "Text Coaching",
            image: `${EnvironmentConfig.imageBaseURL}text-coach.png`,
            description: "Chat with a personal coach and learn new strategies for dealing with stress, personal relationships, and tough transitions.",
            button: "Connect with a Coach",
            navigate: "/app/support-questionnaire",
            service: Services.textCoaching,
            flow: "Text Coaching",
        },
        {
            title: "Short-Term Counseling",
            image: `${EnvironmentConfig.imageBaseURL}short-term.png`,
            description: "Get hands-on help through life’s biggest challenges such as the loss of a loved one, anxiety, loneliness or significant distress.",
            button: "Schedule First Session",
            navigate: "/app/support-questionnaire",
            service: Services.selfSchedule,
            flow: "Self Scheduling",
        },
        {
            title: "Work-Life Request",
            image: `${EnvironmentConfig.imageBaseURL}worklife.png`,
            description: "Request referrals for services in your local area for childcare, elder care, daily living, legal and financial needs.",
            button: "Get a Referral",
            navigate: "/app/worklife-request",
            service: Services.workLifeRequest,
            flow: "Work-Life Request",
        },
        {
            title: "Get Answers",
            image: `${EnvironmentConfig.imageBaseURL}get-ans.png`,
            description: "Chat with a benefits specialist to learn more about the different benefits available to you.",
            button: "Connect with a Specialist",
            navigate: "/app/support-questionnaire",
            service: Services.getAnswers,
            flow: "Text Coaching",
        }
    ];

   const swiperSettings = {
        spaceBetween: 20,
        slidesPerView: 1,
        loop: true,
        speed: 100,
        centeredSlides: false,
        autoplay: { delay: 2500 },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 16,
                width: 200,
                centeredSlides: true,
            },
            375: {
                slidesPerView: 1,
                spaceBetween: 16,
                width: 300,
                centeredSlides: false,
            },
            546: {
                slidesPerView: 1,
                spaceBetween: 16,
                width: 320,
                centeredSlides: false,
            },
            547: {
                slidesPerView: 1,
                spaceBetween: 16,
                width: 400,
                centeredSlides: false,
            },
            728: {
                slidesPerView: 1,
                spaceBetween: 20,
                width: 600,
                centeredSlides: false,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 20,
                width: 992,
                centeredSlides: false,
            },
            1440: {
                slidesPerView: 3,
                spaceBetween: 20,
                width: 1250,
                centeredSlides: false,
            }
        },
        modules: [Autoplay, Pagination, Navigation],
        navigation: {
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
        },
        pagination: { clickable: true }
    };

    const setSwiperRef = (swiper: SwiperCore | null) => {
        sliderRef.current = swiper;
    };

    const goToNext = () => {
        sliderRef.current?.slideNext();
    };

    const goToPrev = () => {
        sliderRef.current?.slidePrev();
    };

    const filteredCards = Cards.filter((card) => {
        if (
            serviceType?.includes("Text Coaching") ||
            serviceType?.includes("Self Scheduling")
        ) {
            return card.flow === Services.workLifeRequest || serviceType?.includes(card.flow);
        }
        return serviceType?.includes(card.flow);
    });
    return (
        <>
            {filteredCards?.length !== 0 && <>
                <div className='individual-support-component'>
                    <div className='support-header'>
                        <div className='title-section'>
                            {
                                icon && <img src={icon} alt='icon' />
                            }
                            <span className='support-title'>{title}</span>
                        </div>
                        <div className='slider-buttons'>
                            <img src={PreviousIcon} alt='previous' className='slider-button-icon' onClick={goToPrev} />
                            <img src={NextIcon} alt='next' className='slider-button-icon' onClick={goToNext} />
                        </div>
                    </div>
                    <div>
                        <Swiper {...swiperSettings} onSwiper={setSwiperRef} className="slider-container">
                            {filteredCards.map((item, index) => (
                                <SwiperSlide key={index + "card"} className="slider-slide">
                                    <SupportCard
                                        key={index + "card"}
                                        title={item.title}
                                        image={item.image}
                                        flow={item.flow}
                                        description={item.description}
                                        path={item.navigate}
                                        button={item.button}
                                        service={item.service}
                                        activeTickets={activeTickets}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </>
            }
        </>
    );
}

export default IndividualSupport;
