import { useEffect, useState } from "react";
import {
  Category,
  Request,
  getRequestObjectsFromCategoryId,
} from "../../../../utils/workLifeData";
import '../../../../styles/globalStyles.scss'
import './RequestOptions.scss'
import { Stepkeys, WorkLifeAnswersObj } from "../../../../views/worklife-request/WorkLifeRequestSteps";

interface RequestOptionsProps {
  setCompletionStatus: (key: string, isCompleted: boolean) => void;
  setAnswers: <K extends keyof WorkLifeAnswersObj>(key: K, value: WorkLifeAnswersObj[K]) => void;
  answers: WorkLifeAnswersObj;
}

const RequestOptions: React.FC<RequestOptionsProps> = ({ answers, setCompletionStatus, setAnswers }) => {
  const categoryId = answers.categories?.categoryId;
  const subCategoryId = answers.subCategory?.subCategoryId
  const [requests, setRequests] = useState<(Category | Request)[]>([]);
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    if (subCategoryId) {
      const response = getRequestObjectsFromCategoryId(subCategoryId);
      if (response) {
        setCategoryName(response.categoryName);
        setRequests(response.items || []);
      }
    }
    else if (categoryId) {
      const response = getRequestObjectsFromCategoryId(categoryId);
      if (response) {
        setCategoryName(response.categoryName);
        setRequests(response.items || []);
      }
    } else {
      setCategoryName("");
      setRequests([]);
    }
  }, [categoryId, subCategoryId]);

  const handleRequest = (request: Category | Request) => {
    if (request.type === "Category") {
      setCompletionStatus(Stepkeys.subCategory, true);
      setCompletionStatus(Stepkeys.requestOption, false);
      setAnswers(Stepkeys.subCategory, { subCategoryId: request.id, subCategoryName: request.name });
    } else {
      setCompletionStatus(Stepkeys.subCategory, true);
      setCompletionStatus(Stepkeys.requestOption, true);
      setAnswers(Stepkeys.requestOption, { requestId: request.id, requestName: request.name });
    }
  };

  return (
    <div className="request-options-page page-container">
      <span className="title">{categoryName}</span>
      <div className="request-grid-container">
        {requests?.map((request, index) => (
          <div key={index} className="request-card-container">
            <div className="request-cards" onClick={() => handleRequest(request)}>
              {request.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RequestOptions;