import React, { useEffect, useRef, useState } from 'react';
import MuxPlayer from "@mux/mux-player-react";
import TitleDescription from '../TitleDescription/TitleDescription';

interface ModuleProps {
    title: string;
    description: string;
    url: string | null;
    onNextButtonClick: () => void;
}

const VideoModule: React.FC<ModuleProps> = ({ title, description, url, onNextButtonClick }) => {
    const playerRef = useRef<any | null>(null);
    const [playbackPosition] = useState<number>(0);

    const handlePause = () => {
        if (playerRef.current) {
            const currentTime = playerRef.current.currentTime;
            localStorage.setItem('playbackPosition', currentTime.toString());
        }
    };

    const handleEnded = () => {
        localStorage.removeItem('playbackPosition');
    };

    useEffect(() => {
        if (playerRef.current && playbackPosition > 0) {
            playerRef.current.currentTime = playbackPosition;
        }
    }, [playbackPosition]);

    return (
        <div className='module-content'>
            <MuxPlayer
                className='mux-video'
                ref={playerRef}
                playbackId={url ?? ""}
                onPause={handlePause}
                onEnded={handleEnded}
                autoPlay
            />
            <TitleDescription title={title} description={description} onNextButtonClick={onNextButtonClick} />
        </div>
    )
}

export default VideoModule