import React from 'react'
import FixedHeaderUserLayout from '../../component/layout/FixedHeaderUserLayout'
import WorkLifeRequestSteps from './WorkLifeRequestSteps'


const index = () => {
  return (
    <FixedHeaderUserLayout children={<WorkLifeRequestSteps />} />
  )
}

export default index