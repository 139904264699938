import { useCallback, useEffect, useState } from "react";
import WorkLifeForm from "../../component/pages/work-life/work-life-form/WorkLifeForm";
import WorkLifeCategories from "../../component/pages/work-life/worklife-categories/WorkLifeCategories";
import RequestOptions from "../../component/pages/work-life/request-options/RequestOptions";
import RequestForm from "../../component/pages/work-life/request-form/RequestForm";
import SuccessMessage from "../../component/pages/work-life/success-message/SuccessMessage";
import { UserInfo } from "../../interfaces/ApiTypes";
import './WorkLifeRequestSteps.scss'

export enum Stepkeys {
    worklifeForm = "worklifeForm",
    categories = "categories",
    subCategory = "subCategory",
    requestOption = "requestOption",
    requestForm = "requestForm",
    successMessage = "successMessage"
}

export type CompletionStatus = {
    worklifeForm: boolean;
    categories: boolean;
    subCategory: boolean;
    requestOption: boolean;
    requestForm: boolean;
    successMessage: boolean;
};

export type WorkLifeAnswersObj = {
    [Stepkeys.worklifeForm]: UserInfo | null;
    [Stepkeys.categories]: { categoryId: string; categoryName: string } | null;
    [Stepkeys.subCategory]: { subCategoryId: string; subCategoryName: string } | null;
    [Stepkeys.requestOption]: { requestId: string; requestName: string } | null;
    [Stepkeys.requestForm]: {
        memberInformation: UserInfo | null;
        workLifeData: {
            category?: string;
            subCategory?: string | null;
            requestName: string;
            answers: Record<string, string>;
        };
    } | null;
};


const WorkLifeRequestSteps = () => {
    const [completionStatus, setCompletionStatus] = useState<CompletionStatus>({
        [Stepkeys.worklifeForm]: false,
        [Stepkeys.categories]: false,
        [Stepkeys.subCategory]: false,
        [Stepkeys.requestOption]: false,
        [Stepkeys.requestForm]: false,
        [Stepkeys.successMessage]: false,
    });

    const [answers, setAnswers] = useState<WorkLifeAnswersObj>({
        [Stepkeys.worklifeForm]: null,
        [Stepkeys.categories]: null,
        [Stepkeys.subCategory]: null,
        [Stepkeys.requestOption]: null,
        [Stepkeys.requestForm]: null,
    });

    const onUpdateCompletionStatus = useCallback((key: string, isCompleted: boolean) => {
        setCompletionStatus((prevStatus) => ({
            ...prevStatus,
            [key]: isCompleted,
        }));
    }, [setCompletionStatus]);

    const onUpdateAnswers = useCallback(
        <K extends keyof WorkLifeAnswersObj>(key: K, value: WorkLifeAnswersObj[K]) => {
            setAnswers((prevAnswers) => ({
                ...prevAnswers,
                [key]: value,
            }));
        },
        []
    );

    const steps = [
        {
            key: Stepkeys.worklifeForm as keyof CompletionStatus,
            component: <WorkLifeForm setCompletionStatus={onUpdateCompletionStatus} setAnswers={onUpdateAnswers} />,
        },
        {
            key: Stepkeys.categories as keyof CompletionStatus,
            component: (
                <WorkLifeCategories
                    setCompletionStatus={onUpdateCompletionStatus} setAnswers={onUpdateAnswers}

                />
            ),
        },
        {
            key: Stepkeys.subCategory as keyof CompletionStatus,
            component: (
                <RequestOptions
                    setCompletionStatus={onUpdateCompletionStatus} setAnswers={onUpdateAnswers}
                    answers={answers}
                />
            ),
        },
        {
            key: Stepkeys.requestOption as keyof CompletionStatus,
            component: (
                <RequestOptions
                    setCompletionStatus={onUpdateCompletionStatus} setAnswers={onUpdateAnswers}
                    answers={answers}
                />
            ),
        },
        {
            key: Stepkeys.requestForm as keyof CompletionStatus,
            component: <RequestForm setCompletionStatus={onUpdateCompletionStatus} setAnswers={onUpdateAnswers} answer={answers} />,
        },
        {
            key: Stepkeys.successMessage as keyof CompletionStatus,
            component: <SuccessMessage />,
        },
    ];

    const currentStepIndex = steps.findIndex((step) => !completionStatus[step.key]);

    const currentStep = steps[currentStepIndex];

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            const warningMessage = "You have unsaved changes. Are you sure you want to leave?";
            event.preventDefault();
            event.returnValue = warningMessage;
            return warningMessage;
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return <div className="worklife-step-page">
        {currentStep && currentStep.component}
    </div>;
};

export default WorkLifeRequestSteps;
